import { SideBarReducerDispatch } from "./constants/sidebar";

type SideBar = { dispatch: SideBarReducerDispatch };

class Singleton {
  // sidebar
  private _SideBar: SideBar;
  get SideBar(): SideBar {
    return this._SideBar;
  }
  set SideBar(value: Partial<SideBar>) {
    this.SideBar = {
      ...this.SideBar,
      ...value
    };
  }

  constructor() {
    this._SideBar = { dispatch: () => {} };
  }
}

const instance = new Singleton();

export default instance;
