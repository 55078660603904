import { StaticImage } from "gatsby-plugin-image";
import Singleton from '@singleton';
import classNames from "classnames";
import ThemeSwitch from "../../../components/theme/ThemeSwitch";

const toggleSideBar = () => Singleton.SideBar.dispatch({ type: 'TOGGLE' });

const TopBar: React.FC<{}> = () => {
  return (
    <div
      className={classNames(
        'fixed z-topbar',
        'h-topbar_height w-full',
        'grid items-center gap-page p-page',
        'bg-white dark:bg-darker',
        'shadow dark:border-b dark:border-gray-600',
        'transition-colors apply-transition-dark_theme',
      )}
      style={{ gridTemplateColumns: '300px 1fr' }}
    >
      <div className="grid gap-page items-center" style={{ gridTemplateColumns: 'repeat(2, fit-content(100%))' }}>
        <div
          className={classNames(
            'flex cursor-pointer px-1 rounded lg:hidden',
            'transition-colors apply-transition-dark_theme',
            'dark:bg-gray-500'
          )}
          onClick={toggleSideBar}
        >
          <StaticImage
            src="../../../images/icons/hamburger.png"
            alt="Toggle for side bar"
            loading="eager"
            placeholder="none"
            layout="fixed"
            quality={100}
            aspectRatio={1}
            width={30}
          />
        </div>
        <StaticImage
          src="../../../images/logos/logo.png"
          alt="Logo"
          loading="eager"
          placeholder="none"
          layout="fixed"
          quality={100}
          aspectRatio={1}
          width={30}
        />
      </div>
      <ThemeSwitch className="justify-self-end" />
    </div>
  );
};

export default TopBar;;
