import type { GatsbyBrowser } from "gatsby";
import gtagjs from 'gtagjs';
import './src/styles/main.css';
import config from './src/config';
import Singleton from './src/singleton';
import Layout from './src/components/app/Layout';
import GlobalProviders from './src/components/app/GlobalProviders';
import { theme } from './src/utils/theme';
import { initializeEasyCookies } from './src/utils/easycookies';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  theme.initialize();
  if (config.env === 'production') {
    gtagjs.gtag('consent', 'default', {
      analytics_storage: 'denied',
      wait_for_update: 2000,
    });
    gtagjs.initialize(config.analytics.measurementId);
    initializeEasyCookies();
  }
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (location.pathname !== prevLocation?.pathname) {
    Singleton.SideBar.dispatch({ type: 'CLOSE' });
  }
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <GlobalProviders>
      {element}
    </GlobalProviders>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <Layout>
      {element}
    </Layout>
  );
};
