import { useContext, createContext, useReducer, useEffect } from "react";
import Singleton from '@singleton';
import { SideBarReducer, SideBarReducerDispatch, SideBarReducerState } from "../constants/sidebar";
import useBodyScrollLocking from '../hooks/use-body-scroll-locking';

const sideBarReducer: SideBarReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN': return { isOpen: true };
    case 'CLOSE': return { isOpen: false };
    case 'TOGGLE': return { isOpen: !state.isOpen };
    default: throw new Error('no action');
  }
};

const initialState: SideBarReducerState = { isOpen: true };

type SideBarContextType = [SideBarReducerState, SideBarReducerDispatch]
const SideBarContext = createContext<SideBarContextType>([
  initialState,
  () => initialState,
]);

export const SideBarContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const handler = useReducer(sideBarReducer, {
    ...initialState,
    isOpen: false,
  });
  useEffect(() => {
    Singleton.SideBar.dispatch = handler[1];
  }, []);
  useBodyScrollLocking(handler[0].isOpen);
  return (
    <SideBarContext.Provider value={handler}>
      {children}
    </SideBarContext.Provider>
  );
};

export const useSideBarContext = () => useContext(SideBarContext);
