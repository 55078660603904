const EASYCOOKIES_CODE = '24D6EIDAX8L8';

type ENV = 'local' | 'production';

const config = {
  env: (process.env.GATSBY_APP_ENV ?? 'local') as ENV,
  siteTitle: 'Kinlom | กินลม',
  siteDescription: 'เว็บไซต์ที่เจ้าของอยากจะทำอะไรก็ได้ ก็มันเว็บผมอ้ะ',
  siteUrl: 'https://kinlom.com',
  siteHostname: 'kinlom.com',
  siteHostnameAliases: ['www.kinlom.com'],
  analytics: {
    measurementId: 'G-78RQ66XSL2',
  },
  easycookies: {
    src: `https://easypdpa.com/api/v2/scripts/easycookies/${EASYCOOKIES_CODE}/easycookies.js`,
    code: EASYCOOKIES_CODE,
  },
};

export default config;
