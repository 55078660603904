import { gtag } from 'gtagjs';
import config from '../config';
import { Theme } from '../constants/theme';
import { theme } from './theme';

export const initializeEasyCookies = () => {
  window.easycookies = {
    code: config.easycookies.code,
    overrideInitialTheme: theme.get() === Theme.Dark ? 'dark' : 'light',
    callback: (cookies) => {
      gtag('consent', 'update', { analytics_storage: cookies.performance ? 'granted' : 'denied' });
    },
  };

  const scriptElem = document.createElement('script');
  scriptElem.src = config.easycookies.src;
  scriptElem.type = 'text/javascript';
  scriptElem.async = true;
  scriptElem.defer = true;
  document.body.appendChild(scriptElem);
};
