import { Link } from "gatsby";
import classNames from "classnames";
import { useSideBarContext } from '../../../contexts/sidebar';
import { Menu, MenuToBlog } from '../../../graphql/cms/navigation';

type SideBarProps = {
  menus: Menu[];
  blogs: MenuToBlog[];
}
const SideBar: React.FC<SideBarProps> = (props) => {
  const [state, dispatch] = useSideBarContext();
  return (
    <>
      <nav
        className={classNames(
          'z-sidebar',
          'p-page',
          'overflow-y-auto',
          'fixed top-topbar_height',
          'h-full w-sidebar_width',
          'bg-white dark:bg-dark',
          'transition-[background-color,transform] apply-transition-dark_theme',
          { '-translate-x-full lg:translate-x-0': !state.isOpen }, // mobile, tablet
        )}
      >
        {props.menus.map(menu => (
          <Link
            key={menu.id}
            to={menu.link.href}
            className={classNames(
              'block',
              // 'transition-colors apply-transition-dark_theme',
              'hover:bg-transparent_black dark:hover:bg-transparent_white',
              'p-1.5',
              'rounded',
              'overflow-hidden'
            )}
          >
            <p className="line-clamp-2 font-medium">{menu.label}</p>
          </Link>
        ))}
        <div
          className={classNames(
            'w-full',
            'my-3',
            'transition-colors apply-transition-dark_theme',
            'border-dashed border-t border-gray-200 dark:border-gray-600'
          )}
        />
        {props.blogs.map(blog => (
          <Link
            key={blog.id}
            to={`/${blog.blog.data.attributes.slug.data.attributes.parentSlug?.data.attributes.value}/${blog.blog.data.attributes.slug.data.attributes.value}`}
            className={classNames(
              'block',
              // 'transition-colors apply-transition-dark_theme',
              'hover:bg-transparent_black dark:hover:bg-transparent_white',
              'p-1.5',
              'rounded',
              'overflow-hidden'
            )}
          >
            <p className="line-clamp-2 font-medium text-sm">{blog.blog.data.attributes.title}</p>
          </Link>
        ))}
      </nav>
      <div
        className={classNames(
          'lg:hidden',
          'z-sidebar_overlay',
          'h-full w-full',
          'bg-[rgba(0,0,0,0.5)] dark:bg-[rgba(255,255,255,0.1)]',
          { 'fixed top-topbar_height': state.isOpen }, // mobile, tablet
          { 'hidden': !state.isOpen }, // desktop
        )}
        onClick={() => dispatch({ type: 'CLOSE' })}
      />
    </>
  );
};

export default SideBar;
