import { SideBarContextProvider } from '../../contexts/sidebar';

const GlobalProviders: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <SideBarContextProvider>
      {children}
    </SideBarContextProvider>
  );
};

export default GlobalProviders;
