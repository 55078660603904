import { THEME_KEY } from '../constants/local-storage';
import { Theme } from '../constants/theme';

export const theme = {
  initialize: () => {
    if (localStorage.getItem(THEME_KEY) === Theme.Dark/* || (!(THEME_KEY in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)*/) {
      theme.set(Theme.Dark);
    } else {
      theme.set(Theme.Light);
    }
  },
  get: (): Theme => {
    const theme = localStorage.getItem(THEME_KEY);
    return theme as Theme;
  },
  set: (t: Theme) => {
    localStorage.setItem(THEME_KEY, t);
    if (t === Theme.Dark) {
      document.documentElement.classList.add('dark');
      document.documentElement.setAttribute('data-color-mode', 'dark');
      window.easycookies?.setTheme?.(() => 'dark');
    }
    else {
      document.documentElement.classList.remove('dark');
      document.documentElement.setAttribute('data-color-mode', 'light');
      window.easycookies?.setTheme?.(() => 'light');
    }
  },
  toggleSet: () => {
    if (theme.get() === Theme.Light) {
      theme.set(Theme.Dark);
    } else {
      theme.set(Theme.Light);
    }
  },
};
