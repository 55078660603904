import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import { theme } from "../../utils/theme";

const ThemeSwitch: React.FC<{ className?: string }> = (props) => {
  return (
    <button
      className={classNames(
        'w-[30px] h-[30px]',
        'overflow-hidden',
        'rounded-full',
        'transition-all apply-transition-dark_theme',
        'rotate-[0deg]',
        'dark:rotate-[180deg]',
        props.className
      )}
      onClick={theme.toggleSet}
    >
      <div
        className={classNames(
          'overflow-hidden',
          'flex justify-center items-center',
          'w-full',
          'bg-white',
          'transition-all apply-transition-dark_theme',
          'h-[90%]',
          'dark:h-[10%]',
        )}
      >
        <StaticImage
          className="cursor-pointer opacity-100 dark:opacity-0"
          src="../../images/icons/sun.png"
          alt="Toggle for side bar"
          loading="eager"
          placeholder="none"
          layout="fixed"
          quality={100}
          aspectRatio={1}
          width={20}
        />
      </div>
      <div
        className={classNames(
          'overflow-hidden',
          'flex justify-center items-center',
          'w-full',
          'bg-darker',
          'transition-all apply-transition-dark_theme',
          'h-[10%]',
          'dark:h-[90%]'
        )}
      >
        <StaticImage
          className="cursor-pointer opacity-0 dark:opacity-100"
          src="../../images/icons/moon.png"
          alt="Toggle for side bar"
          loading="eager"
          placeholder="none"
          layout="fixed"
          quality={100}
          aspectRatio={1}
          width={20}
        />
      </div>
    </button>
  );
};

export default ThemeSwitch;
