import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { Menu, MenuToBlog, NavigationQuery } from "../../../graphql/cms/navigation";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import config from "../../../config";

const META_IMAGE = `${config.siteUrl}/temporary.png`;

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const data = useStaticQuery<NavigationQuery>(graphql`
    query {
      cms {
        ...Navigation
      }
    }
  `);
  const menus = data.cms.navigation.data.attributes.menus.filter(m => m.__typename === 'CMS_ComponentNavigationMenu') as Menu[];
  const blogs = data.cms.navigation.data.attributes.menus.filter(m => m.__typename === 'CMS_ComponentNavigationMenuToBlog') as MenuToBlog[];
  return (
    <>
      <Helmet>
        {/* TODO: query from CMS */}
        <title>{config.siteTitle}</title>
        <meta name="description" content={config.siteDescription} />
        <meta name="og:description" content={config.siteDescription} />
        <meta name="image" content={META_IMAGE} />
        <meta property="og:image" content={META_IMAGE} />
      </Helmet>
      <TopBar />
      <SideBar menus={menus} blogs={blogs} />
      <div
        className={classNames(
          'pt-topbar_height pb-10 ml-0 lg:ml-sidebar_width',
          'transition-spacing apply-transition-dark_theme'
        )}
      >
        {children}
      </div>
    </>
  );
};

export default Layout;
