import { useLayoutEffect } from 'react';

const useBodyScrollLocking = (isLocked: boolean) => {
  useLayoutEffect(() => {
    if (isLocked) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = null as any;
      if (!window.document.body.getAttribute('style')) {
        window.document.body.removeAttribute('style');
      }
    }
  }, [isLocked]);
};

export default useBodyScrollLocking;
